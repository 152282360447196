<template>
  <div id="Telegram">
    <div class="card-header bg-brand px-3 py-4 rounded-t-lg">
      <p>
        <span class="brand-color-dark">Telegram Bot</span><br />
        Der StadtbahnfahrerClub Bot versorgt Dich täglich mit Deinem Dienstplan.
      </p>
    </div>
    <div class="card-content">
      <font-awesome-icon
        :icon="['fab', 'telegram']"
        class="icon"
        style="font-size: 5rem"
      /><br /><br />
      <div v-if="date">
        <div class="alertbox border-green-600">
          <div class="alertbox-icon bg-green-600">
            <font-awesome-icon icon="check" class="icon"/>
          </div>
          <div class="alertbox-content">
            <p class="alertbox-heading text-green-500">
              <b>Erfolgreich eingerichtet!</b>
            </p>
            <p class="alertbox-maincontent">
              Verknüpft seit {{ getFormatedDate(date) }}
            </p>
          </div>
        </div>
        <!-- <p>
          <font-awesome-icon icon="check" class="color-success" />
          <b>Erfolgreich eingerichtet!</b><br />Verknüpft seit
          {{ getFormatedDate(date) }}<br /><br />
        </p> -->
        <p>
          <router-link to="benachrichtigungen" class="link"
            >Benachrichtigungen verwalten</router-link
          >
        </p>
        <br /><br />
        <button class="btn-designless" @click="resetTelegramConnection()">
          <span class="color-danger">Verknüpfung zurücksetzen</span>
        </button>
      </div>
      <div v-else>
        Mit dem Nachrichten-Dienst
        <a href="https://telegram.org/" target="_newTab" class="link"
          >Telegram</a
        >
        kannst du kostenfrei und komfortable Echtzeit-Benachrichtigungen von unserem StadtbahnfahrerClub
            Bot erhalten, ohne unsere Website aufzurufen. Unser Bot versorgt täglich mit Deinem Dienstplan und mit wichtigen Status-Hinweisen zu Deinem StadtbahnfahrerClub Konto.<br /><br />
        <router-link to="../telegram" class="btn-designless"
          ><span class="color-success">Einrichtung starten</span></router-link
        >
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

import A from "../exchange/A.vue";
export default {
  name: "Telegram",
  components: {
    A,
  },
  data() {
    return {
      isLoading: true,
      date: false,
    };
  },
  methods: {
    resetTelegramConnection() {
      if(confirm('Möchtest Du die Verknüpfung wirklich zurücksetzen? Du erhältst dann keine Benachrichtigungen per Telegram und kannst keine Informationen mehr über den Telegram Bot abfragen. Du kannst die Einrichtung aber jederzeit erneut durchführen.')) {
        this.date = false
        this.$store.dispatch("resetTelegramConnection", this.dbId).then((a) => {
          if (a.status == true) {

          }
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch("fetchTelegramStatusForUser").then((a) => {
      if (a.status) {
        this.isLoading = false;
        if (a.response.data.length == 0) {
        } else {
          this.date = a.response.data[0].date_created;
        }
      }
    });
  },
  computed: {
    ...mapGetters([
      "getFormatedDate"
    ]),
  },
};
</script>